<template>
	<div class="container">
		<div class="data-main" v-if="dataRecord != null">
			<div class="breadcrumb" v-if="dataRecord.c_id != 0">
				{{ dataRecord.parent_category ? dataRecord.parent_category : '' }}{{ dataRecord.parent_category ? ' > ' +
					dataRecord.category : dataRecord.category }}
			</div>
			<div class="title">{{ dataRecord.title }}</div>
			<!-- TODO：资料需要付费后才可查看附件 -->
			<div class="title-box">
				<!-- <div class="top-title">附件</div> -->
				<template v-if="isNotNeedPay === -1">
					<div>加载中...</div>
				</template>
				<template v-if="isNotNeedPay === 0">
					<div>
						<div>抱歉，该功能需要单次充值才能继续使用，或者到个人页<span style="color: red">充值会员</span>以享受最高优惠！</div>
						<div class="pay__panel">
							<template v-if="qrCode">
								<div class="qrcode-box" ref="qrcode"></div>
								<div class="right">
									<div class="price">{{ price }}</div>
									<img class="pay_logo" :src="payLogo" />
									<div class="tip">使用微信扫码支付，支付完请稍等片刻！</div>
								</div>
							</template>
							<div v-else-if="qrErrMsg">{{ qrErrMsg }}</div>
							<template v-else>支付二维码加载中...</template>
						</div>
					</div>
				</template>
				<template v-else>
					<div>
						<div class="title-box">
							<!-- <div class="top-title">描述</div> -->
							<!-- <div class="description">{{ dataRecord.description }}</div> -->
							<div class="description" v-html="description"></div>
						</div>
						<div class="upload-file__bd-box">
							<div class="upload-file__bd">
								<div v-for="(item, index) in dataRecord.attachments" :key="index" class="item"
									:style="index % 8 == 7 ? 'margin-right:0;' : ''" @click="onViewFile(item)">
									<viewer v-if="item.src !== null"
										:images="dataRecord.attachments.filter(p => p.src !== null).map(p => p.src)">
										<img :src="item.src">
									</viewer>
									<img v-else :src="nopic">
									<div class="file-name file-name2">{{ item.name }}</div>
									<a class="file-name" :href="item.src || item.url" download="" target="_blank">下载</a>
								</div>
							</div>
						</div>
						<div style="text-align: right; padding-top: 10px;">
							<Button type="primary" icon="ios-cloud-download-outline" style="margin-right: 20px;"
								@click="onDataDownload">打包下载</Button>
						</div>
					</div>
				</template>
			</div>
			<div class="info">
				<!-- <viewer>
          <div class="cover-box">
            <img class="cover" :src="dataRecord.image !== '' ? dataRecord.image : nopic" alt="">
          </div>
        </viewer> -->
				<div class="info-r">
					<p class="line">
						<span class="label">地区：</span>
						<span class="value" v-if="dataRecord.city_id !== null">{{ dataRecord.province }} -
							{{ dataRecord.city }}</span>
						<span class="value" v-else>全国</span>
					</p>
					<p class="line">
						<span class="label">发布人：</span>
						<span class="value">{{ dataRecord.real_name }}</span>
					</p>
					<p class="line">
						<span class="label">单位：</span>
						<span class="value">{{ dataRecord.company }}</span>
					</p>
					<p class="line">
						<span class="label">发布时间：</span>
						<span class="value">{{ dataRecord.create_date }}</span>
					</p>
				</div>
			</div>
		</div>
		<div class="not-found" v-else>{{ loading ? '加载中...' : '未找到供需信息！' }}</div>
		<!-- <iframe id="preview_frame" class="description-frame" runat="server" frameborder="no" border="0" marginwidth="0" marginheight="0" allowtransparency="yes"></iframe> -->
		<PreviewDocx v-model="docxInfo" />
	</div>
</template>

<script>
import {
	mapGetters
} from 'vuex'
import {
	getDataRecord,
	downloadDataZip
} from '@/api/data.js'
import PreviewDocx from '@/components/PreviewDocx.vue'
import {
	getMemberSetting
} from '@/api/setting.js'
import QRCode from 'qrcodejs2'
import {
	checkReleaseIsPaid,
	getReleasePayQr,
	checkTransaction
} from '@/api/user'

export default {
	name: 'DataRecordDetail',
	components: {
		PreviewDocx
	},
	data() {
		return {
			loading: true,
			nopic: require('@/assets/images/nopic.png'),

			id: null,
			dataRecord: null,
			description: '',

			docxInfo: null,

			/* 支付相关字段 */
			isNotNeedPay: -1,
			price: 0,
			payLogo: require('@/assets/images/wechat_pay.png'),
			qrCode: null,
			tradeNo: null,
			qrErrMsg: null,
			timers: null
		}
	},
	computed: {
		...mapGetters(['userInfo', 'hasLogin'])
	},
	watch: {
		qrCode(newVal) {
			if (this.$refs.qrcode) {
				this.$refs.qrcode.innerHTML = ''
			}
			if (newVal != null) {
				this.$nextTick(() => {
					this.showQRcode()
				})
			}
		}
	},
	mounted() {
		/* // 设置预览的 iframe
		const doc =
			document.getElementById('preview_frame').contentDocument ||
			document.frames.preview_frame.document

		// doc.body.style = 'margin:20px;'

		// 设置基地址
		const base = document.createElement('base')
		base.href = this.$config.baseUrl()
		doc.head.appendChild(base)

		this.htmlBase = base
		this.htmlDoc = doc
		this.htmlDoc.body.innerHTML = null */

		this.initData()
	},
	methods: {
		/* 支付 开始 */
		showQRcode() {
			const _ = new QRCode(this.$refs.qrcode, {
				text: this.qrCode, // 需要转换为二维码的内容
				width: 160,
				height: 160,
				colorDark: '#000',
				colorLight: '#fff',
				correctLevel: QRCode.CorrectLevel.H
			})

			if (this.timers) {
				clearInterval(this.timers)
			}

			this.timers = setInterval(async () => {
				await this.getOrderState()
			}, 3000)
		},
		async requestPayQr() {
			this.qrErrMsg = null
			try {
				console.log(this.id)
				const params = {
					release_type: 'download-data',
					id: this.id
				}
				const res = await getReleasePayQr(params)
				this.tradeNo = res.content.trade_no
				this.qrCode = res.content.code_url
			} catch (error) {
				this.$Message.error(error.message)
				this.qrCode = null
				this.qrErrMsg = error.message
			}
		},
		async getOrderState() {
			if (this.tradeNo == null) return
			try {
				const params = {
					trade_no: this.tradeNo
				}
				await checkTransaction(params)
				this.$Message.success('支付成功')
				if (this.timers) {
					clearInterval(this.timers)
				}
				this.isNotNeedPay = 1
				this.initData()
			} catch (error) { }
		},
		/* 支付 结束 */
		async initData() {
			this.id = this.$route.query.id
			console.log(this.$route)
			await this.getDataRecordData()
			await this.checkPayStatus()
		},
		async getDataRecordData() {
			try {
				const params = {
					id: this.id
				}
				const res = await getDataRecord(params)
				this.loading = false
				this.dataRecord = res.content.row
				this.description = this.dataRecord.description.replace(/src="/gi, 'src="' + this.$config
					.baseUrl() + '/')
				// this.htmlDoc.body.innerHTML = this.dataRecord.description
				console.log(this.dataRecord)
			} catch (error) {
				this.$Message.error(error.message)
			}
		},
		async checkPayStatus() {
			// 如果该用户发布需要收费，则检查是否已付费，否则弹出收费窗口
			let res = await getMemberSetting()
			switch (this.userInfo.vip_info.level) {
				case 0:
					this.price = parseInt(res.content.free.fee.download_data)
					break
				case 1:
					this.price = parseInt(res.content.level1.fee.download_data)
					break
				case 2:
					this.price = parseInt(res.content.level2.fee.download_data)
					break
			}
			if (this.price > 0) {
				res = await checkReleaseIsPaid({
					release_type: 'download-data',
					id: this.id
				})
				this.isNotNeedPay = parseInt(res.content)
			} else {
				this.isNotNeedPay = 1
			}
			// console.log('价格: ' + this.price, '需要支付: ' + this.isNotNeedPay === 0 ? '是' : '否')

			if (this.isNotNeedPay === 0) { // 需要付费且为新增
				// 获取收费二维码
				this.requestPayQr()
			} else { }
		},
		onViewFile(row) {
			console.log(row)
			if (row.file_extension === 'doc' || row.file_extension === 'docx' ||
				row.file_extension === 'xls' || row.file_extension === 'xlsx' ||
				row.file_extension === 'ppt' || row.file_extension === 'pptx') {
				// this.docxInfo = { detail: row }
				window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(row.url + "?t=" + Date.now()))
			} else if (row.file_extension === 'pdf') {
				window.open(row.url)
			}
		},
		async onDataDownload() {
			try {
				this.$Spin.show()
				const res = await downloadDataZip({
					id: this.id
				})

				let filename = res.headers['content-disposition']
				filename = decodeURIComponent(filename.split('filename=')[1])
				filename = filename.replace(/"/g, '')

				const blob = res.data
				// 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
				// IE10以上支持blob但是依然不支持download
				const downloadElement = document.createElement('a')
				if ('download' in downloadElement) {
					const href = window.URL.createObjectURL(blob) // 创建下载的链接
					downloadElement.href = href
					downloadElement.download = filename
					document.body.appendChild(downloadElement)
					downloadElement.click() // 点击下载
					window.URL.revokeObjectURL(href) // 释放掉blob对象
					document.body.removeChild(downloadElement) // 下载完成移除元素
				} else {
					navigator.msSaveBlob(blob, filename)
				}
				this.$Spin.hide()
			} catch (err) {
				this.$Spin.hide()
				this.$Message.error(err.message)
			}
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	background-color: #fff;
	min-height: calc(100vh - 64px);
	padding-bottom: 50px;
}

.data-main {

	.breadcrumb {
		padding-top: 14px;
		color: #888;
		font-size: 12px
	}

	.title {
		font-size: 20px;
		color: #333;
		padding-top: 16px;
	}

	.info {
		display: flex;
		padding-top: 20px;

		.cover-box {
			width: 500px;
			height: 375px;
			border: 1px solid #f0f0f0;

			.cover {
				width: 100%;
				height: 100%;
			}
		}

		.info-r {
			padding-left: 10px;

			.line {
				display: flex;
				padding: 4px 0;
				font-size: 16px;

				.label {
					width: 100px;
					text-align: right;
					color: #999;
				}

				.value {
					padding-left: 30px;
				}
			}
		}
	}

	.title-box {
		padding-top: 20px;

		.top-title {
			position: relative;
			padding-left: 10px;
			font-size: 20px;
			color: #333;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 4px;
				height: 100%;
				background-color: #369;
			}
		}

		.description {
			padding-top: 10px;

			font-size: 16px;
			line-height: 24px;
			word-break: break-all;
		}
	}
}

.not-found {
	font-size: 16px;
	color: #333;
	padding: 20px 0;
}

.description-frame {
	width: 100%;
	min-height: 500px;
	padding-top: 10px;

	font-size: 16px;
	line-height: 24px;
	word-break: break-all;
}

.upload-file__bd-box {
	display: inline-block;
	min-width: 132px;
}

.upload-file__bd {
	position: relative;
	box-sizing: border-box;
	min-height: 200px;
	margin-top: 10px;
	padding: 10px 10px 0;
	border: solid 1px #dbdde1;
	display: flex;
	justify-content: flex-start;
	flex-flow: wrap;

	.item {
		position: relative;
		width: 100px;
		height: 180px;
		margin-bottom: 10px;
		margin-right: 8px;
		cursor: pointer;

		img {
			position: absolute;
			width: 100px;
			height: 100px;
			// width: 100%;
			// height: 100%;
			object-fit: cover;
		}

		.file-name {
			position: absolute;
			bottom: 0;
			height: 20px;
			line-height: 20px;
			background-color: #fff;
			text-align: center;
			width: 100%;

			overflow: hidden;
			word-break: break-all;
			/* break-all(允许在单词内换行。) */
			text-overflow: ellipsis;
			/* 超出部分省略号 */
			display: -webkit-box;
			/** 对象作为伸缩盒子模型显示 **/
			-webkit-box-orient: vertical;
			/** 设置或检索伸缩盒对象的子元素的排列方式 **/
			-webkit-line-clamp: 1;
			/** 显示的行数 **/
		}

		.file-name2 {
			bottom: 30px;
			height: 40px;

			overflow: hidden;
			word-break: break-all;
			/* break-all(允许在单词内换行。) */
			text-overflow: ellipsis;
			/* 超出部分省略号 */
			display: -webkit-box;
			/** 对象作为伸缩盒子模型显示 **/
			-webkit-box-orient: vertical;
			/** 设置或检索伸缩盒对象的子元素的排列方式 **/
			-webkit-line-clamp: 2;
			/** 显示的行数 **/
		}
	}

	.item:hover {
		.cover {
			display: block;
		}
	}
}

.pay__panel {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;

	.right {
		display: flex;
		height: 160px;
		flex-direction: column;
		justify-content: center;
		margin-left: 20px;

		.price {
			color: #ff3a3a;
			text-align: center;
			font-size: 40px;
			line-height: 60px;
			font-weight: 500;

			&::after {
				content: " 元";
				font-size: 14px;
			}
		}

		.pay_logo {
			flex-shrink: 0;
			width: 120px;
			height: 40px;
			object-fit: contain;
		}

		.tip {
			line-height: 40px;
			font-size: 15px;
		}
	}

	.err {
		text-align: center;
	}
}

.qrcode-box {
	display: inline-block;
	width: 160px;
	height: 160px;
}
</style>
